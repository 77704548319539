


















































import { Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { ButtonComponent },
  inheritAttrs: false,
})
export default class JoinNetworkWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  @Prop({ required: false, default: null })
  private readonly noAuthTitle!: string;

  @Prop({ required: false, default: null })
  private readonly noAuthSubtitle!: string;

  @Prop({ required: false, default: null })
  private readonly noAuthBtnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly noAuthBtnRoute!: string;

  private readonly avatars = [
    '/img/users/user_1.png',
    '/img/users/user_2.png',
    '/img/users/user_3.png',
    '/img/users/user_4.png',
    '/img/users/user_5.png',
  ];

  created(): void {
    this.setDataConfig();
  }

  private onClick(): void {
    const btnRoute = this.authUser ? this.btnRoute : this.noAuthBtnRoute;
    if (btnRoute) {
      let external = btnRoute.startsWith('//');
      if (external) {
        window.open(btnRoute, '_blank');
      } else {
        try {
          const url = new URL(btnRoute);
          external = !!url.protocol;
          if (external) {
            window.open(btnRoute, '_blank');
          }
        } catch {
          let r = btnRoute;
          const matches = btnRoute.match(/(%[a-zA-Z-_]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '').trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.$router.push(r);
        }
      }
    } else {
      this.$router.push('/sign-up');
    }
  }
}
